<template>
  <div>
    <zhongtuoIndex v-if="$route.params.plat_type =='train'"></zhongtuoIndex>
    <jiazhangIndex v-else-if="$route.params.plat_type =='jiazhang'"></jiazhangIndex>
  </div>
  
</template>

<script>
import zhongtuoIndex from './IndexZhongtuo'
import jiazhangIndex from './IndexJiazhang'
document.ondragstart = function () {
  return false;
};
export default {
  name: "index",
  components:{zhongtuoIndex,jiazhangIndex},
  data() {
    return {
      
    };
  },
  async mounted() {
    
  },
  computed: {
    
  },
  methods: {
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar{
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color:#F4F4F4;
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC
}
::-webkit-scrollbar-thumb{
 border-radius:5px;
  background-color: #DEDEE4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
