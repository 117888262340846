<template>
  <div class="index" v-loading="pageLoading" ref="index">
    <el-dialog :title="videoTitle" :visible.sync="videoShow" @close="videoClose" width="80%">
      <video :src="videoUrl" controls ref="videoPlayer" id="player" style="width:100%;object-fit:cover;"></video>
    </el-dialog>
    <div class="index-main">
      <el-carousel :interval="3000" arrow="hover" height="470px" class="banner">
        <el-carousel-item v-if="banner.length==0">
          <div class="banner-item">
            <img :src="$logoBig"/>
          </div>
        </el-carousel-item>
        <el-carousel-item v-for="(item, index) of banner" :key="index">
          <van-image
            class="banner-item"
            width="100%"
            height="470"
            :src="item.cover"
            fit="cover"
            @click="jumpBannerLesson(item)"
          />
        </el-carousel-item>
      </el-carousel>
     
    </div>
    <list1
      v-for="(item,index) in category" v-if="item.list.length" :key="item.id"
      :list="item.list.slice(0,4)"
      :name="item.name"
      :imgUrl="item.cover"
      :id="item.id"
    ></list1>
  </div>
</template>

<script>
document.ondragstart = function () {
  return false;
};
import list1 from "@/components/IndexList";
import list from "@/components/IndexList06";
import listCat from "@/components/IndexListCat";
export default {
  name: "index",
  components: { list, listCat, list1 },
  data() {
    return {
      img:[
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/IndexTitle1.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E4%B8%AD%E6%89%98%E8%AE%B2%E5%9D%9B-%E9%A6%96%E9%A1%B5-%E7%AC%AC%E4%B8%89%E7%89%88_03.png'
      ],
      cateList: [],
      active: 0,
      pageLoading: true,
      imgScaleIndex: '',
      vipLesson: [],
      vipIndex: 0,
      ideaActive: 0,
      ideaData: [],
      liveData: [],
      loading: true,
      checked: false,
      ideaAllData: [],
      category: [],
      banner: [],
      videoShow: false,
      videoUrl:'',
      videoTitle:'',
      width: 1200,

      tyLesson: [],
      yuer:[],
      zhuangjiaList:[],
      // 婴幼
      yyList2: [],
      yyList2Name: '',
      yyList3: [],
      yyList3Name: '',
      liveImg1: [
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%9B%B4%E6%92%AD%E8%AF%BE-%E6%95%99%E7%A0%94.jpg",
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%9B%B4%E6%92%AD%E8%AF%BE-%E8%BF%90%E8%90%A5%E7%AE%A1%E7%90%86.jpg",
      ],
      liveImg2: [
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%AE%B6%E9%95%BF%E5%A4%A7%E8%AE%B2%E5%A0%82.jpg",
      ],
    };
  },
  async mounted() {
    this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
    await this.getData();
    // this.$nextTick(()=>{
    //   this.width = this.$refs.index.offsetWidth

    // })
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    liveImg() {
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.liveImg1;
      } else {
        return this.liveImg2;
      }
    },
  },
  methods: {
    tojiazhang() {
      console.log(window.location);
      let url = window.location.origin + window.location.pathname + "#/jiazhang03/index";
      window.open(url);
    },
    // 去列表
    toList(url, id, index) {
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    ideaNavChange(index) {
      this.ideaActive = index;
      let numList = [];
      while (numList.length < 3) {
        let num = parseInt(Math.random() * this.ideaAllData.length);
        if (numList.indexOf(num) == -1) {
          numList.push(num);
        }
      }
      (this.ideaData = []),
        (this.ideaData = [
          this.ideaAllData[numList[0]],
          this.ideaAllData[numList[1]],
          this.ideaAllData[numList[2]],
        ]);
    },
    jumpList(name) {
      this.$until.toPageOpen("/" + name);
    },
    jumpBannerLesson(item) {
      if(!this.$until.loginRequire()){
        return
      }
      if(item.video_url){
        this.videoShow = true
        this.videoUrl = item.video_url
        this.videoTitle = item.name
        this.$nextTick(()=>{
          this.$refs.videoPlayer.play();
        })
      }else if(item.url){
        if(item.url.indexOf('http')==-1){
          this.$until.toPageOpen(`/detail2?id=${item.url}`);
        }else{
          window.open(item.url)
        }
      }
    },
    videoClose(){
      this.$refs.videoPlayer.pause();
      this.videoShow = false   
    },
    jumpLesson(item) {
      if(this.$until.loginRequire()){
        this.$until.toPageOpen(`/detail2?id=${item.id}`)
      }
      // this.$until.toPageOpen(`/detail2?id=${item.id}`);
    },
 
   
    async getData() {
      this.pageLoading = true;
      
      let res = await this.$api({
        method: "get",
        url: "/api/index?flat_id="+this.plat,
      });
      this.pageLoading = false;
      if (res.code == 0) {
       
        this.category = res.data.parent_list;
        // this.yuer =  res.data.category_list.find((item) => item.id == 221).list.filter(v=>  v.list[0].length).map(vv=>vv.list[0]).flat();
        
        // this.zhuangjiaList =  res.data.category_list.find((item) => item.id == 230).list.filter(v=>  v.list[0].length).map(vv=>vv.list[0]).flat();
        // console.log(this.zhuangjiaList)
        // this.liveData = res.data.live_stream || [];
        // console.log(res.data.category_list)
        
        
        this.banner = res.data.banner
      } else {
        this.$toast(res.msg);
      }

   
    },
    play(item) {
      let { video_id, ccid, name, url, goods_id, id } = item;
      console.log(video_id, ccid);
      let type = "ali";
      if (!video_id && ccid) {
        type = "cc";
        id = ccid;
      } else if (url) {
        type = "url";
      } else {
        this.$toast("没有视频");
        return;
      }
      this.$router.push(
        `./player?id=${id}&videoId=${video_id}&name=${name}&type=${type}&from=课程&url=${encodeURI(
          url
        )}&goods_id=${goods_id}`
      );
    },
    liveplay(item) {
      if(!this.$until.loginRequire()){
       return
      }
      let { name, video_url, id, video_id, goods_id } = item;

      if (video_url || video_id) {
        this.$store.commit("SET_VIDEO", null);
        let url = `/player?id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(
          video_url
        )}&from=直播&goods_id=${goods_id}&type=ali`;
        this.$until.toPageOpen(url);
      } else {
        this.$toast("没有视频");
      }

      // this.$api({
      //   method: "get",
      //   url: "/api/livestream/click",
      //   data: {
      //     id,
      //   },
      // });
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.$store.commit("SET_PLAT", to.params.plat_type);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #dedee4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
.index {
  width: 100%;
  .index-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 470px;
    // align-items: flex-start;

    .classification {
      flex: 0 1 180px;
      width: 180px;
      height: 100%;
      box-shadow: 0 5px 10px rgb(51 51 51 / 6%);
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      .list {
        padding: 8px 0;
        display: flex;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        li {
          flex: 1;
          span {
            font-weight: bold;
          }
        }
        .list-item {
          position: relative;
          width: 100%;
          height: 37px;
          line-height: 37px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 16px;
          color: #333;
          font-size: 16px;
          box-sizing: border-box;

          &:hover {
            background: #edf8ff;
            color: #0080d6;
          }
          i {
            color: #c6c6c6;
          }
        }
      }
    }
    .userInfo {
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userBg.jpg");
      display: flex;
      flex-direction: column;
      align-items: center;
      .userLogo {
        padding-top: 20px;
        text-align: center;
        padding-bottom: 10px;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 5px;
        }
        span {
          font-size: 16px;
          color: 393939;
          // font-family: "黑体";
        }
      }
      .studyTime {
        margin-bottom: 80px;
        p {
          font-size: 12px;
          width: 122px;
          display: flex;
          height: 58px;
          align-items: center;
          b {
            flex: 1;
            font-size: 22px;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
      .btn {
        cursor: pointer;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: #0080d6;
      }
    }
    .noLogin {
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userNoLogin.png");
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        margin-top: 296px;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: #0080d6;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        span {
          width: 1px;
          height: 15px;
          margin: 0 10px;
          display: block;
          background: #fff;
        }
        p {
          cursor: pointer;
        }
      }
    }
  }

  .banner {
    flex: 1;
    overflow: hidden;
    height: 100%;
    background: #f7f7f7;
    .banner-item {
      border-radius: 10px;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      >img{
        width: auto;
        height: auto;
      }
    }
  }

  .hot {
    width: 100%;
    margin: 16px auto 0;
  }
  .module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-family: "SourceHanSans";
    }
    span {
      color: #006bb3;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .course-card2 {
    margin-top: 87px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100vw;
      min-width: 1200px;
      height: 100%;
      background: #fff;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
    }
    .module-title {
      padding-top: 42px;
      padding-bottom: 11px;
    }
    @keyframes toScale {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
    @keyframes toScaleSmall {
      from {
        transform: scale(1.2);
      }
      to {
        transform: scale(1);
      }
    }
    .course-cover {
      flex-shrink: 0;

      ::v-deep img {
        cursor: pointer;
      }
    }
    .imgBig{
      ::v-deep img {
        animation: toScale 0.3s;
        transform: scale(1.2);
      }
      
    }
    .imgSmall{
      ::v-deep img {
        animation: toScaleSmall 0.3s;
        transform: scale(1);
      }
      
    }
    .course-card2-item {
      padding: 29px 0;
      width: 100%;
      cursor: pointer;
      display: flex;
      &:not(:last-of-type) {
        background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/line.jpg")
          bottom no-repeat;
      }
    }
    .course-card-cover {
      width: 560px;
      height: 315px;
      border-radius: 10px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .course-card-detail {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      .course-card-title {
        font-size: 20px;
        font-weight: bold;
      }
      .detail {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #666666;
        line-height: 26px;
      }
      .lessonTime {
        flex: 1;
        font-size: 16px;
        color: #006bb3;
      }
      > div {
        display: flex;
        justify-content: space-between;
        color: #7f7f7f;
        .price {
          color: #ff6c00;
          font-size: 12px;
          b {
            font-size: 24px;
          }
        }
      }
    }
  }
  .live {
    .course-card-title {
      margin-bottom: 20px !important;
      p {
        height: 48px !important;
        -webkit-line-clamp: 2 !important;
        font-size: 16px !important;
      }
    }
  }
  .levelItem {
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        flex-shrink: 0;
        padding-bottom: 0;
        display: block;
      }
    }
    .course-card-detail {
      padding: 16px;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      .course-card-title {
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 4px;
        font-size: 16px;
        overflow: auto;
        height: 80px;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 24px;
          color: #333333;
          font-size: 16px;
        }
        .des {
          margin-top: 5px;
          font-size: 14px;
          color: #7f7f7f;
        }
        .lessonTm {
          font-size: 12px;
          color: #006bb3;
          font-weight: bold;
        }
      }
    }
  }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .levelTitle {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p {
        cursor: pointer;
        font-size: 16px;
        color: #0080d6;
      }
    }
    .course-card {
      margin-right: 20px;
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
    .course-card:nth-of-type(5n) {
      margin: 0;
    }
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }

    .levelCate {
      cursor: pointer;
      width: calc((100% - 60px) * 0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b {
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184a8c;
        padding-top: 39px;
        padding-left: 19px;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #dbdbdb;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      border-radius: 6px;
      color: #0080d6;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #0080d6;
      }
    }
  }

  .idea,
  .cert {
    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      margin-right: 20px;
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;
      height: 216px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0px 0px 10px #0000000f;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 12px 12px 0 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .tip {
          position: absolute;
          top: 0;
          left: 0px;
          z-index: 1;
          img {
            width: 78px;
            height: 35px;
          }
        }
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #0080d6;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #0080d6;
        }
      }
    }
  }

  .top-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    padding-bottom: 10px;
    .top-card {
      width: calc((100% - 80px) / 5);
      margin-right: 20px;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
      cursor: pointer;
      flex: 0 0 calc((100% - 80px) / 5);

      &:hover {
        cursor: pointer;
        transform: translate(0, -6px);
        box-shadow: 0px 14px 36px rgb(0 0 0 / 6%);
        transition: all 0.3s ease;
      }

      .top-card-cover {
        width: 100%;
        height: 224px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        .tip {
          position: absolute;
          top: 0;
          left: 0px;
          z-index: 1;
          img {
            width: 50px;
            // height: 35px;
          }
        }
      }

      .course-card-detail {
        padding: 16px 16px 20px;

        .course-card-title {
          line-height: 24px;
          font-weight: bold;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 16px;
        }

        .course-card-subtitle {
          line-height: 18px;
          margin-bottom: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 12px;
        }

        .course-card-btn-container {
          margin-top: 20px;
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 30px;

          .course-card-learners {
            font-size: 11px;
            line-height: 18px;
          }

          .course-card-btn {
            cursor: pointer;
            border-radius: 6px;
            line-height: 30px;
            font-weight: 500;
            border-radius: 6px;
            color: #a40001;
            background: #f7f7f7;
            padding: 0 20px;
            font-size: 14px;

            // &:hover {
            //   color: #fff;
            //   background: #a40001;
            // }
          }
        }
      }
    }
  }
  .category {
    .tab {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        margin-right: 10px;
        height: 32px;
        flex: 1;
        text-align: center;
        line-height: 32px;
        height: 32px;
        line-height: 32px;
        margin-right: 20px;
        display: inline-block;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        color: #454545;
        background: #fff;
        &:hover {
          background: #0080d6;
          color: #fff;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: #0080d6;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  // .index {
  //   .banner {
  //     width: calc(100% - 60px);
  //     margin: 16px auto 0;
  //   }
  // }
}
</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
}
</style>
